<template>
  <div class="centerwrap">
    <div class="bannerbox">
      <img src="../../../assets/imgs/webimg/bookbanner.png" alt="">
    </div>
    <div class="centerbox">
      <el-menu router :default-active="$route.path" class="el-menu-demo" mode="horizontal">
        <el-menu-item index="/bookMain/bookAll">精品图书</el-menu-item>
        <!-- <el-menu-item index="/bookMain/bookMy">我的图书</el-menu-item>
        <el-menu-item index="/bookMain/bookWaitReleas">待发布图书</el-menu-item>
        <el-menu-item index="/bookMain/bookOrdered">已购图书</el-menu-item> -->
      </el-menu>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {};
  },
  methods: {
    showtext(e) {
      e.currentTarget.className = "showtext";
    },
    hidetext(e) {
      e.currentTarget.className = "";
    },
  },
};
</script>
<style  lang="less" scoped>
.centerwrap {
  position: relative; 
 
}
.centerbox{
 width: 1200px;
      width: 1200px;
    margin: 15px auto;
}
/deep/.collapse {
  i {
    color: #aaa;
  }
}
/deep/.el-menu--horizontal {
  // padding: 10px 0 0 0;
  & > .el-menu-item {
    border-bottom: 2px solid #16aed0;
    padding: 4px 10px;
    height: 46px;
    line-height: 32px;
    font-size: 16px;
    color: #222;
    margin-right: 20px;
    &.is-active {
      color: #16aed0;
    }
  }
}
.bannerbox img{
width:100%;
height: auto;
}
</style>